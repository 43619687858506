.SingleImage{

}

.SingleImageLockout{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1,1,1, 0.5);
    z-index: 1000;
    overflow: hidden;
}

.SingleImageLockoutImg {
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translateX(-50%);
}



.SingleImageLockout img {
    position: relative;
    object-fit: contain;
    height: calc(100vh - 135px);
    overflow-y: hidden;
    margin-top: -50px;


    -webkit-transition: all 1.9s ease;
    -moz-transition: all 1.9s ease;
    -ms-transition: all 1.9s ease;
    -o-transition: all 1.9s ease;
    transition: all 1.9s ease;
    
    vertical-align: middle;
}

.SingleImageLockout img:hover {
    -webkit-transform:scale(1.1); /* Safari and Chrome */
    -moz-transform:scale(1.1); /* Firefox */
    -ms-transform:scale(1.1); /* IE 9 */
    -o-transform:scale(1.1); /* Opera */
    transform:scale(1.1);
}