a.nlinkl:hover
{
    color: lightblue !important;
} 

a.nlinkl:visited
{
    color: white;
}

a.nlinkl:link
{
    color: white;
}

a.nlinkd:hover
{
    color: lightblue !important;
} 

a.nlinkd:visited
{
    color: black;
}
a.nlinkd:link
{
  color: black;
}

a.active{
    color: lightblue !important;
}

ul {
    list-style: none;
    padding: unset;
    margin: unset;
  }
  
  li {
    padding: 14px;
    transition: all linear 0.3s;
  }
  
  li:hover {
    background: gray;
    cursor: pointer;
    color: #fff;
  }
  
  .dropdown-menu {
    background: #fff;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    transition: all linear 0.3s;
    z-index: 10000;
  }

  button {
    background: #000;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    color: #fff;
    padding: 12px 25px;
    border: none;
    cursor: pointer;
  }

  .mobileNavBar{
    display: flex;
    justify-content: space-between;
  }

  .mobileLogo{
    padding: 5px;
  }