.testimonialList{
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.page-container {
    display: flex;
    position: relative;
    min-height: 100vh;
    justify-content: center;
  }
  
#content-wrap {
    padding-bottom: 1.5rem;    /* Footer height */
}
  
.footer {
    width: 100%;
    background-color: gray;
    color: white;
    text-align: center;
    position: relative;
}

.TestimonialWidgetWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
