.testimonialList{
    margin-top: 100px;
    justify-content: center;
    align-items: center;
    width: 100%;

}

#page-container {
    position: relative;
    min-height: 100vh;
  }
  
#content-wrap {
    padding-bottom: 1.5rem;    /* Footer height */
}
  
#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.5rem;            /* Footer height */
}

