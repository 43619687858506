.MainImageDesktop{
}

.MainImageDesktop img {

    object-fit: contain;
    width: 100%;
    overflow-y: hidden;
    margin-top: -200px;
    z-index: 9;

    -webkit-transition: all .9s ease;
    -moz-transition: all .9s ease;
    -ms-transition: all .9s ease;
    -o-transition: all .9s ease;
    transition: all .9s ease;
    
    vertical-align: middle;
}

.MainImageDesktop img:hover {
    -webkit-transform:scale(1.1); /* Safari and Chrome */
    -moz-transform:scale(1.1); /* Firefox */
    -ms-transform:scale(1.1); /* IE 9 */
    -o-transform:scale(1.1); /* Opera */
    transform:scale(1.1);
}

.MainImageMobile{
}

.MainImageMobile img {

    object-fit: contain;
    width: 100%;
    z-index: 9;

    -webkit-transition: all .9s ease;
    -moz-transition: all .9s ease;
    -ms-transition: all .9s ease;
    -o-transition: all .9s ease;
    transition: all .9s ease;
    
    vertical-align: middle;
}

.MainPageImage{
    width: 100%;
    height: 1280px;
}